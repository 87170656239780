import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useTimeOnPage } from '../analytics';
import ProfileEdit from '../components/ProfileEdit';
import LessonPlanAdd from '../components/LessonPlan';
import SchoolLessonAdd from '../components/SchoolLessonAdd';
import yted from '../apis/yted';

const ProfilePage = ({ profile, setProfile }) => {
    useTimeOnPage('profilePage');
    
    const navigate = useNavigate();

    const navigateToLessonPlans = () => {
        navigate('lesson_plans');
    }

    const navigateToSchoolLessons = () => {
        navigate('school_lessons');
    }

    const defaultProfile = () => {
        return {
            first_name: '',
            gender: 'Boy',
            grade_level: '',
        }
    }

    const handleCancel = () => {
        navigate('/settings');
    };

    const handleUpdateProfile = async (editedProfile) => {
        setProfile(editedProfile);
        navigateToLessonPlans();
    };

    const handleAddLessonPlan = async (lessonPlan) => {
        console.log(JSON.stringify(lessonPlan));
        navigateToSchoolLessons();
    };

    const handleUpdateLessons = async (lessons) => {
        const response = await yted.post('/user_profile/school_lessons', { text: lessons });
        if (response.status === 200) {
            const newProfile = await yted.get('/user_profile?n=' + Date.now());
            setProfile(newProfile.data);
        } else {
            console.error('Failed to add lesson');
        }
        handleCancel();
    };

    return (
        <div>
            <Routes>
                <Route path="" element={
                    <ProfileEdit profile={defaultProfile()} onOk={handleUpdateProfile} onCancel={handleCancel} />
                } />
                <Route path="lesson_plans" element={
                    <LessonPlanAdd onOk={handleAddLessonPlan} onCancel={handleCancel} />
                } />
                <Route path="school_lessons" element={
                    <SchoolLessonAdd onOk={handleUpdateLessons} onCancel={handleCancel} />
                } />
            </Routes>
        </div>
    );
};

export default ProfilePage;
