import React from 'react';
import GoogleButton from 'react-google-button'
import { useError } from '../contexts/ErrorContext';

const ErrorNotification = () => {
    const { error, setError } = useError();

    if (!error) return null;

    const handleParentLogin = () => {
        const apiUrl = process.env.REACT_APP_LF_API_URL;
        window.location.href = `${apiUrl}/auth/login`;
    };

    function renderTokenRefreshError() {
        return <div>
            <p>Ask your parent to login to LF. This will allow you to access YouTube contents.</p>
            <div className="mx-auto mt-8">
                <div className="inline-flex flex-col items-center border border-gray-300 rounded-2xl px-20">
                    <div className="m-4">Parent Login</div>
                    <GoogleButton onClick={handleParentLogin} className="mb-6" />
                </div>
            </div>
        </div>;
    }

    function renderGenericError(message) {
        return <div>
            <p>{message}</p>
            <button onClick={() => setError('')} className="mt-8 border border-black px-6 py-1.5">
                Close
            </button>
        </div>;
    }

    return (
        <div className="fixed top-0 left-0 w-full bg-white p-8 z-20">
            <div className="border border-4 border-red-500 rounded-lg p-8 md:w-4/5 md:mx-auto">
            {
                error.type === 'token_refresh_error' 
                ? renderTokenRefreshError()
                : renderGenericError(error.message)
            }            
            </div>
        </div>
    );
};

export default ErrorNotification;
