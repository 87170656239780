export function donutChart({ percentage }) {
    return (
        <div className="relative my-1 w-5 h-5 rounded-full bg-gray-200" style={{ '--percentage': `${percentage}%` }}>
            <div className="absolute inset-0 flex items-center justify-center">
                <div className="w-3 h-3 bg-white rounded-full"></div>
            </div>
            <style jsx="true">{`
                .relative {
                    background: conic-gradient(
                        blue var(--percentage), 
                        #a9a9a9 0
                    );
                }
            `}</style>
        </div>
    );
};
