import { InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css';

const LatexRenderer = ({ text }) => {
    // Split the text with \( ... \) delimiters
    const parts = text.split(/(\\\([^)]*\\\))/);

    return (
        <span>
            {parts.map((part, index) => {
                if (part.startsWith('\\(') && part.endsWith('\\)')) {
                    const latex = part.slice(2, -2); // Remove the \( and \) symbols
                    return <InlineMath key={index} math={latex} />;
                } else {
                    return <span key={index}>{part}</span>;
                }
            })}
        </span>
    );
};

export default LatexRenderer;
