import React, { useState } from 'react';

const SchoolLessonAdd = ({ onOk, onCancel }) => {
    const [lessons, setLessons] = useState('');

    const handleLessonChange = (event) => {
        setLessons(event.target.value);
    };

    return <div>
        <h1 className="text-xl m-4">Add School Lessons</h1>
        <textarea
            className="border border-gray-300 w-full p-1"
            rows="10"
            value={lessons}
            onChange={handleLessonChange} />
        <div className="flex gap-2 justify-center m-4">
            <button className="bg-blue-500 text-white py-2 px-4 rounded" onClick={() => onOk(lessons)}>OK</button>
            <button className="bg-blue-500 text-white py-2 px-4 rounded" onClick={onCancel}>Cancel</button>
        </div>
    </div>
    ;
};

export default SchoolLessonAdd;
