import React, { useState } from 'react';
import './LoadingButton.css';

const LoadingButton = ({ onClick, text, isLoading = false, ...otherProps }) => {
    const [isInternalLoading, setIsInternalLoading] = useState(false);

    function isLoadingNow() {
        return isLoading || isInternalLoading;
    }

    async function handleClick() {
        setIsInternalLoading(true);
        await onClick();
        setIsInternalLoading(false);
    }

    return <div className="flex items-center">
        <button 
            className={`${isLoadingNow() ? "bg-gray-400" : "bg-blue-500"} text-white py-2 px-4 rounded whitespace-nowrap`} 
            onClick={handleClick} disabled={isLoadingNow()}
            {...otherProps}>
            {text}
        </button>
        {
            isLoadingNow() && <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        }
    </div>;
};

export default LoadingButton;
