import React, { useRef, useState } from 'react';
import { Mutex } from 'async-mutex';
import './Speech.css';
  
const Speech = ({ getSpeech }) => {
    const [isBufferingAudio, setIsBufferingAudio] = useState(false);

    const audioRef = useRef(null);
    const mediaSourceRef = useRef(null);
    const sourceBufferRef = useRef(null);
    const queueRef = useRef([]);
    const isPlayingRef = useRef(false);
    const lock = useRef(null);

    const EOS_MARKER = '<EOS_MARKER>';

    const handleSpeak = async () => {
        setIsBufferingAudio(true);

        if (sourceBufferRef.current && sourceBufferRef.current.updating) {
            sourceBufferRef.current.abort();
        }
        mediaSourceRef.current = null;
        sourceBufferRef.current = null;
        queueRef.current = [];
        isPlayingRef.current = false;
        lock.current = new Mutex();

        const newMediaSource = new MediaSource();
        mediaSourceRef.current = newMediaSource;

        const maybePlay = async () => {
            if (!isPlayingRef.current && !sourceBufferRef.updating) {
                try {
                    await audioRef.current.play();
                    isPlayingRef.current = true;
                } catch (error) {
                    console.error('Failed to play audio:', error);
                }
            }
        };

        newMediaSource.addEventListener('sourceopen', async () => {
            const mimeType = 'audio/mpeg';
            const sourceBuffer = newMediaSource.addSourceBuffer(mimeType);
            sourceBufferRef.current = sourceBuffer;

            const appendBuffer = async (value) => {
                const l = lock.current;
                await l.runExclusive(async () => {
                    queueRef.current.push(value);
                });

                await processQueue();

                setIsBufferingAudio(false);
            };

            const processQueue = async () => {
                const q = queueRef.current;
                const l = lock.current;

                if (q.length > 0 && !sourceBuffer.updating) {
                    await l.runExclusive(async () => {
                        const item = q.shift();
                        if (item === EOS_MARKER) {
                            newMediaSource.endOfStream();
                        } else {
                            if (!sourceBuffer.updating)
                                sourceBuffer.appendBuffer(item);
                            else
                                q.unshift(item);
                        }
                    });
                    
                    await maybePlay();
                }
            };

            sourceBuffer.addEventListener('updateend', async () => {
                await processQueue();
            });

            const response = await getSpeech();
            const reader = response.body.getReader();

            const pump = async (controller) => {
                const { done, value } = await reader.read();
                if (done) {
                    await appendBuffer(EOS_MARKER);
                    controller.close();
                    return;
                }
                await appendBuffer(value);
                await pump(controller);
            };

            const stream = new ReadableStream({
                start(controller) {
                    pump(controller);
                }
            });

            await stream;
        });

        audioRef.current.src = URL.createObjectURL(newMediaSource);
        maybePlay();
    };

    return <span className="ml-1">
        <img src="icon-audio.png" alt="speak" onClick={handleSpeak} onTouchEnd={handleSpeak}
            className="inline inline-mb-1 w-7 h-7 p-1.5 rounded-lg hover:bg-gray-200 opacity-80" />
        <img src="loading-indicator.gif" alt=""
            className={`${isBufferingAudio ? "inline-mb-1" : "hidden"} inline w-4 h-4 ml-1`} />
        <audio ref={audioRef} />
    </span>
};

export default Speech;
