import React, { useState } from 'react';

const InfoTooltip = ({ text }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const toggleTooltip = () => {
    setTooltipVisible(!isTooltipVisible);
  };

  return (
    <div className="relative inline-block text-center p-2">
      <div
        onClick={toggleTooltip}
        className="cursor-pointer bg-blue-500 text-white rounded-full h-5 w-5 flex items-center justify-center text-sm"
      >
        i
      </div>
      {isTooltipVisible && (
        <div className="absolute top-0 left-9 transform w-max bg-gray-700 text-white text-sm rounded p-2 shadow-lg z-10">
          {text}
        </div>
      )}
    </div>
  );
};

export default InfoTooltip;
