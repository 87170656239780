import React, { useState } from 'react';

const ProfileEdit = ({ profile, onOk, onCancel }) => {
    const [editedProfile, setEditedProfile] = useState(profile);

    const handleProfileChange = (e) => {
        const { name, value } = e.target;
        setEditedProfile(prevState => ({ ...prevState, [name]: value }));
    };

    return <div>
        <div className="grid grid-cols-2 gap-2 items-center text-left mb-4">
            <div className="text-right">Fist Name: </div>
            <div>
                <input
                    type="text"
                    className="border border-gray-300 p-1"
                    name="first_name"
                    value={editedProfile.first_name}
                    onChange={handleProfileChange} />
            </div>
            <div className="text-right">Grade Level: </div>
            <div>
                <input
                    type="text"
                    className="border border-gray-300 p-1"
                    name="grade_level"
                    value={editedProfile.grade_level}
                    onChange={handleProfileChange} />
            </div>
        </div>

        <button className="bg-blue-500 text-white py-2 px-4 rounded mr-2" onClick={() => onOk(editedProfile)}>OK</button>
        <button className="bg-blue-500 text-white py-2 px-4 rounded" onClick={onCancel}>Cancel</button>
    </div>
    ;
};

export default ProfileEdit;
