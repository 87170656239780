import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

const initializeAnalytics = () => {
    ReactGA.initialize(process.env.REACT_APP_GA4_ID || 'G-4WF3FDNFGL');
};

const _updateConsent = (value) => {
    ReactGA.gtag('consent', 'update', {
        ad_storage: value,
        analytics_storage: value,
    });
}
const setUserId = (userId) => {
    _updateConsent('granted');
    ReactGA.set({ userId });
};

const setUserProperties = (properties) => {
    ReactGA.set(properties);
};

const setNoDataCollection = () => {
    _updateConsent('denied');
    ReactGA.set({ userId: null });
};

const logPageView = (path) => {
    ReactGA.send({ hitType: 'pageview', page: path });
};

const logEvent = ({ category, action, label, props }) => {
    ReactGA.event({ category, action, label, ...props });
};

const useTimeOnPage = (category, maxMinutes = 5, minMinutes = 0) => {
    const [startTime, setStartTime] = useState(Date.now());
    const action = 'time_spent';

    useEffect(() => {
        setStartTime(Date.now());

        const handleBeforeUnload = (label) => {
            const endTime = Date.now();
            const timeSpent = Math.min(
                (endTime - startTime) / 1000, // time spent in seconds
                maxMinutes * 60,
            );

            if (timeSpent >= minMinutes * 60) {
                logEvent({
                    category: category,
                    action: action,
                    label: label,
                    props: {
                        value: timeSpent,
                        nonInteraction: true,
                    }
                });
            }
        };

        window.addEventListener('beforeunload', () => handleBeforeUnload('beforeUnload'));

        return () => {
            handleBeforeUnload('unmount');
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
};

export { 
    initializeAnalytics, 
    setUserId, 
    setUserProperties, 
    setNoDataCollection, 
    logPageView, 
    logEvent, 
    useTimeOnPage,
};
