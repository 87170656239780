import React, { useState, useEffect, useRef, Children } from 'react';
import { logEvent } from '../analytics';
import './Carousel.css'; // Import any custom CSS if needed

const Carousel = ({ name, children }) => {
  const [index, setIndex] = useState(0);

  const carouselRef = useRef(null);
  const scrollTimeout = useRef(null);

  const items = Children.toArray(children);
  const itemCount = items.length;

  const isSmallScreen = () => {
    return window.matchMedia('(max-width: 639px)').matches;
  }

  const handleScroll = (e) => {
    const itemsPerScreen = isSmallScreen() ? 1 : 2;

    const { scrollLeft, clientWidth } = e.target;
    const itemWidth = clientWidth / itemsPerScreen;
    const newIndex = Math.round(scrollLeft / itemWidth) % itemCount;
    setIndex(newIndex);

    if (scrollTimeout.current) {
      clearTimeout(scrollTimeout.current);
    }
    scrollTimeout.current = setTimeout(() => {
      const carouselElement = carouselRef.current;
      if (!carouselElement) return;
      
      const allItemsWidth = itemWidth * itemCount;
      if (carouselElement.scrollLeft < allItemsWidth) {
        carouselElement.scrollLeft += allItemsWidth;
      } else if (carouselElement.scrollLeft > allItemsWidth * 2) {
        carouselElement.scrollLeft -= allItemsWidth;
      }

      logEvent({
        category: `carousel`,
        action: 'scroll',
        label: `Item ${newIndex}`,
        props: {
          name: name,
          newIndex: newIndex,
        }
      });
    }, 50);
  };

  useEffect(() => {
    const itemsPerScreen = isSmallScreen() ? 1 : 2;
    const carouselElement = carouselRef.current;
    carouselElement.addEventListener('scroll', handleScroll);
    carouselElement.scrollLeft = itemCount * (carouselElement.clientWidth / itemsPerScreen);

    return () => {
      carouselElement.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="relative w-full">
      <div
        ref={carouselRef}
        className="flex overflow-x-scroll snap-x snap-mandatory scrollbar-hide"
      >
        {[...items, ...items, ...items].map((item, i) => (
          <div
            key={i}
            className="flex-shrink-0 w-full sm:w-1/2 snap-start"
          >
            {item}
          </div>
        ))}
      </div>
      <div className="bottom-0 left-0 right-0 flex justify-center p-2">
        {items.map((_, i) => (
          <div
            key={i}
            className={`h-2 w-2 rounded-full mx-1 ${index === i ? 'bg-gray-400' : 'bg-gray-200'}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
