import axios from 'axios';

axios.defaults.withCredentials = true;

const apiUrl = process.env.REACT_APP_LF_API_URL || 'http://localhost:5001';

function _createClient(timeout) {
  return axios.create({
    baseURL: apiUrl,
    timeout: timeout,
  });
}

export async function yted_get(path, params, setError, timeout = 5000) {
  try {
    return await _createClient(timeout).get(path, { params: params });
  }
  catch (error) {
    console.error(error);
    if (error.response && error.response.data && error.response.data.errors) {
      setError(error.response.data.errors[0]);
    }
    return null;
  }
};

export async function yted_post(path, params, setError, timeout = 5000) {
  try {
    return await _createClient(timeout).post(path, params);
  }
  catch (error) {
    console.error(error);
    if (error.response && error.response.data && error.response.data.errors) {
      setError(error.response.data.errors[0]);
    }
    return null;
  }
};

export default axios.create({
  baseURL: apiUrl
});
