import React, { useState, useEffect } from 'react';
import { useTimeOnPage } from '../analytics';
import VideoDetail from '../components/VideoDetail';

function VideoPage({ profile, updateScore }) {
    useTimeOnPage('videoPage', 30);
    useTimeOnPage('videoWatchedPage', 30, 3);

    const [selectedVideoId, setSelectedVideoId] = useState(null);

    useEffect(() => {
        const video_id = new URLSearchParams(window.location.search).get('vid');
        const channel_id = new URLSearchParams(window.location.search).get('cid');
        if (video_id) {
            setSelectedVideoId({video_id, channel_id});
        }
    }, []);

    useEffect(() => {
        return () => {
            updateScore();
        };
    }, [updateScore]);

    return <VideoDetail videoId={selectedVideoId?.video_id} channelId={selectedVideoId?.channel_id} />;
};

export default VideoPage;
