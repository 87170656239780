import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useError } from '../contexts/ErrorContext';
import { yted_get, yted_post } from '../apis/yted';
import { getProfilePicture } from '../apis/profile';

async function updatePictureUrl(profile, setError) {
    return await yted_post(
        '/child/profile/picture_url', 
        { picture_url: profile.picture_url },
        setError
    );
}

const Profile = ({ profile, setProfile, onEditProfile }) => {
    const [studyPlans, setStudyPlans] = useState([]);
    const [isEditPicture, setIsEditPicture] = useState(false);

    const emojis = [
        '🎾', '⚽️', '🏀', '⚾️', '🏐', '🏓', '⛳️', '🛹',
        '🐶', '🐱', '🐭', '🦊', '🐻', '🐻‍❄️', '🐯', '🦁',
        '🍎', '🍊', '🍓', '🫐', '🥭', '🍌', '🍉', '🥝',
        '🚗', '🚕', '🚙', '🚌', '🚎', '🏎', '🚓', '🚑',
        '🎸', '🎹', '🎷', '🎺', '🎻', '🪕', '🥁', '🪘',
    ];

    const { setError } = useError();

    const navigate = useNavigate();

    const fetchStudyPlans = async () => {
        const response = await yted_get('/lesson_plan/', {}, setError);
        if (response !== null)
            setStudyPlans(response.data.study_plans);
    };

    const onViewStudyPlan = (studyPlan) => {
        navigate(`/studyPlan/${studyPlan.id}`);
    };

    const onPictureUrl = () => {
        setIsEditPicture(true);
    };

    const onEmoji = async (emoji) => {
        const newProfile = { ...profile, picture_url: `emoji://${emoji}` };
        await updatePictureUrl(newProfile, setError);

        setProfile(newProfile);
        setIsEditPicture(false);
    };

    useEffect(() => {
        if (profile) {
            fetchStudyPlans();
        }
    }, []);

    return profile === null ? (
        <div>Please login</div>
    ) : (
        <div className="text-left mx-auto max-w-screen-lg">
            <div className="mb-4 flex items-center relative">
                <div className="w-24 h-24 rounded-full bg-gray-200 flex items-center justify-center cursor-pointer"
                    onClick={onPictureUrl}>
                    {
                        getProfilePicture(profile, emojis[0])
                    }
                </div>
                {
                    isEditPicture &&
                    <div className="absolute top-6 left-6 right-6 bg-white border border-gray-500 rounded-lg p-4">
                    {
                        emojis.map((emoji, index) => (
                            <div key={index} className="inline-block cursor-pointer p-2 hover:bg-gray-200 rounded-lg" 
                                    onClick={() => onEmoji(emoji)}>
                                {emoji}
                            </div>
                        ))
                    }
                    </div>
                }
                <div className="text-center ml-2">{profile.first_name}</div>
            </div>

            <h1 className="text-xl font-semibold">Profile</h1>

            <table className="">
                <tbody>
                    <tr>
                        <td className="p-1 pl-0">Fist Name:</td>
                        <td className="p-1">{profile.first_name}</td>
                    </tr>
                    <tr>
                        <td className="p-1 pl-0">Grade Level:</td>
                        <td className="p-1">{profile.grade_level}</td>
                    </tr>
                </tbody>
            </table>

            <div className="flex my-2">
                <button className="bg-blue-500 text-white py-2 px-4 rounded mr-2" onClick={onEditProfile}>
                    Edit profile
                </button>
            </div>

            <h1 className="text-xl font-semibold mt-8">Study plans</h1>

            <table className="border border-gray-200 mt-2">
                <thead className="bg-gray-200">
                    <tr>
                        <th className="font-normal p-1">Subject</th>
                        <th className="font-normal p-1">Learning goals</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        studyPlans.map((studyPlan, index) => (
                            <tr key={index} className="border border-gray-200">
                                <td className="p-1 pr-4">{studyPlan.subject}</td>
                                <td className="p-1 pr-6">{studyPlan.goal}</td>
                                <td className="p-1">
                                    <button className="bg-blue-500 text-white py-1 px-4 rounded cursor-pointer mr-2"
                                        onClick={() => onViewStudyPlan(studyPlan)}>
                                        View
                                    </button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
};

export default Profile;
