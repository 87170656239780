import React from 'react';

const Star = ({ filled }) => (
  <span style={{ fontSize: '2em', color: filled ? 'gold' : 'lightgray' }}>
    ★
  </span>
);

const StarRating = ({ rating }) => {
  return (
    <div>
      {Array.from({ length: rating }, (_, index) => (
        <Star key={index} filled={index < rating} />
      ))}
    </div>
  );
};

export default StarRating;
