import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useTimeOnPage } from '../analytics';
import StudyPlanDetail from '../components/StudyPlanDetail';

function StudyPlanPage({ profile }) {
    useTimeOnPage('studyPlanPage');

    return <div className="md:w-4/5 mx-auto">
        <Routes>
            <Route path=":studyPlanId" element={
                <StudyPlanDetail />
            } />
        </Routes>
    </div>
};

export default StudyPlanPage;
