import React, { useState } from 'react';
import LoadingButton from './LoadingButton';
import FeedbackButton from './FeedbackButton';
import { donutChart } from '../apis/donutChart';
import Carousel from './Carousel';
import InfoTooltip from './InfoTooltip';

function _progressStyle(videoId, progressDict) {
    const progress = progressDict[videoId];
    if (progress === undefined) {
        return 'hidden';
    } else if (progress === 100) {
        return 'w-full';
    } else {
        const progressStyle = 'w-' + Math.max(1, Math.floor(progress / 20)).toString() + '/5';
        return progressStyle;
    }
}

function _getElement(arr, index, defaultValue) {
    return arr && index < arr.length ? arr[index] : defaultValue;
}

function _updateElement(arr, index, newValue, defaultValue) {
    if (index >= arr.length) {
        arr = arr.concat(Array(index - arr.length + 1).fill(defaultValue));
    }
    return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
}

function _renderVideo(feedItems, video, reportedVideos, onVideoSelect) {
    const videoId = video.id.videoId;
    const { title, thumbnails, channelTitle, channelId } = video.snippet;

    return (
        <div key={videoId} className="flex-col border rounded-2xl p-3 relative !bg-white">
            {/* Thumbnail */}
            <div className="relative aspect-video rounded-xl overflow-hidden flex items-center justify-center bg-black">
                <img
                    src={thumbnails.high.url}
                    alt={title}
                    className="w-full cursor-pointer object-fill"
                    onClick={() => onVideoSelect(video, feedItems.study_plan_id, feedItems.current_position)}
                />
                <div className={`z-10 absolute bottom-0 left-0 h-1 bg-red-500 ${_progressStyle(videoId, feedItems.progress_by_video_id)}`}></div>
            </div>

            {/* Title */}
            <div className="flex-1 mt-4">
                <h4 className="font-bold cursor-pointer" onClick={() => onVideoSelect(video, feedItems.study_plan_id, feedItems.current_position)}>
                    {title}
                </h4>
                <p>{channelTitle}</p>
            </div>

            <div className="absolute bottom-0 right-0 mb-2 mr-2 flex">
                <FeedbackButton contentIds={[videoId, channelId]} data={{lesson_id: feedItems.lesson_id}} />
            </div>
        </div>
    );
}

const Feed = ({ videos: feed, onVideoSelect, onStudyPlanSelect, onNext, onPrev }) => {
    const [videoCounts, setVideoCounts] = useState([]);
    const [reportedVideos, setReportedVideos] = useState({});

    const DEFAULT_VALUE = 0;

    if (!feed) {
        return <div>Loading...</div>;
    }

    const onShowMore = (index, moveBy, maxValue) => {
        let newValue = _getElement(videoCounts, index, DEFAULT_VALUE) + moveBy;
        if (newValue < 0)
            newValue += maxValue;
        else if (newValue >= maxValue)
            newValue -= maxValue;

        setVideoCounts(
            _updateElement(videoCounts, index, newValue, DEFAULT_VALUE));
    };

    const renderedTopicVideos = feed.map((feedItems, feedIndex) => {
        return <div key={feedIndex}>
            <h2 className="my-4 md:flex md:items-center">
                <div className="flex items-center">
                    <div
                        className="text-xl font-bold cursor-pointer mr-6"
                        onClick={() => onStudyPlanSelect(feedItems.study_plan_id)}>
                        {feedItems.topic}
                    </div>
                </div>

                <div className="flex items-center">
                {
                    feedItems.completed_video_ids.length >= feedItems.lesson_num_videos && 
                    <div className="mr-2">
                        <LoadingButton onClick={() => onNext(feedItems.study_plan_id)} text="Next lesson" />
                    </div>
                }
                {
                    feedItems.completed_video_ids.length >= 0 &&
                    <div className="flex items-center">
                        {donutChart({
                            percentage: Math.min(
                                100, 
                                Math.floor(
                                    feedItems.completed_video_ids.length / 
                                    feedItems.lesson_num_videos * 100))
                        })}
                        <div className="ml-1">
                            {feedItems.completed_video_ids.length}/{feedItems.lesson_num_videos} video(s) completed
                        </div>
                        {
                            feedItems.completed_video_ids.length === 0 &&
                                <div className="ml-2">
                                    <InfoTooltip text="To complete a video, watch it and pass its quiz" />
                                </div>
                        }
                    </div>
                }
                </div>
            </h2>
            <div>
                <Carousel name={feedItems.study_plan_id}>
                    {feedItems.items.map((video) => 
                        <div key={video.id} className="mr-4">
                        {
                            _renderVideo(feedItems, video, reportedVideos, onVideoSelect)
                        }
                        </div>
                    )}
                </Carousel>
            </div>
            <div className="flex justify-center mt-12">
                <div className="w-[266px] h-[66px] overflow-hidden">
                    <img src="https://developers.google.com/static/youtube/images/developed-with-youtube-logos-2x.png" 
                        alt="Developed with YouTube" className="w-[266px] h-auto" />
                </div>
            </div>
        </div>
    });

    return <div className="text-left">
        {renderedTopicVideos}
    </div>;
};

export default Feed;
